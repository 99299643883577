<template>
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchUsers"
      ref="SSearch"
      v-if="SSearchShow"
    ></s-search>
    <div class="table-button table-button-flex">
      <div>
        <a-button
          v-if="
            isShowBtns.indexOf('PackageTemplate-/api/packageTemplate/add') > -1
          "
          type="primary"
          @click="GoToBuildPackage(record, 'add')"
          >新增</a-button
        >
      </div>
      <div>
        <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
          >刷新</a-button
        >
        <columns-set
          tableName="PackageTemplate"
          :columns="columns"
          @changeColumns="changeColumns"
        ></columns-set>
      </div>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="ptId"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: '800px', y: 'calc(82vh - 150px )' }"
      tableLayout="fixed"
    >
      <!--  @change="changeTable" -->
      <span slot="ptBcId" slot-scope="text">
        <div>{{ CompaniesResult(text) }}</div>
      </span>

      <span slot="tbranchcompany" slot-scope="text">
        <div>{{ text.bcName }}</div>
      </span>
      <span slot="tpackageType" slot-scope="text">
        <div>{{ text ? text.ptName : "" }}</div>
      </span>
      <span slot="ptCreateTime" slot-scope="text, record">
        <div v-if="record.ptCreateTime == ''">{{ "-" }}</div>
        <div>{{ $Format(record.ptCreateTime).slice(0, 10) }}</div>
      </span>
      <!-- 操作 -->
      <span slot="action" slot-scope="text, record" class="action">
        <a
          class="editBtn"
          v-if="
            isShowBtns.indexOf('PackageTemplate-/api/packageTemplate/update') >
            -1
          "
          @click="GoToBuildPackage(record, 'edit')"
          >详情</a
        >

        <a-popconfirm
          v-if="
            isShowBtns.indexOf('PackageTemplate-/api/packageTemplate/delete') >
            -1
          "
          title="确定删除此条套餐信息吗?"
          @confirm="() => onDelete(record)"
        >
          <a class="delName">删除</a>
        </a-popconfirm>
      </span>
    </s-table>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { SelectOptions } from "@/config/SelectOptions.js";
import {
  GetPackageTemplateList,
  DeletePackageTemplate,
} from "@/api/apiJF/package";
import { GetCompanies } from "@/api/device";

// 搜索条件
const queryItems = [
  {
    label: "公司名称",
    value: "ptBcIds",
    type: "select",
    selectOption: "CompanyListUnAll",
  },
];
const columns = [
  {
    title: "套餐名称",
    width: "120px",
    scopedSlots: { customRender: "mbDesc" },
    dataIndex: "ptName",
    align: "center",
  },
  {
    title: "套餐编号",
    width: "120px",
    scopedSlots: { customRender: "ptNumber" },
    dataIndex: "ptNumber",
    align: "center",
  },
  {
    title: "公司名称",
    width: "120px",
    scopedSlots: { customRender: "tbranchcompany" },
    dataIndex: "tbranchcompany",
    align: "center",
  },

  {
    title: "套餐类别",
    width: "120px",
    scopedSlots: { customRender: "tpackageType" },
    dataIndex: "tpackageType",
    align: "center",
  },
  // {
  //   title: "施工量",
  //   width: "200px",
  //   scopedSlots: { customRender: "ptCreateTime" },
  //   dataIndex: "ptCreateTime",
  //   align: "center",
  // },
  {
    title: "每平方单价",
    width: "100px",
    scopedSlots: { customRender: "ptPrice" },
    dataIndex: "ptPrice",
    align: "center",
  },
  {
    title: "说明",
    // width: "200px",
    scopedSlots: { customRender: "ptDesc" },
    dataIndex: "ptDesc",
    align: "center",
  },
  // {
  //   title: "模板概要",
  //   width: "200px",
  //   scopedSlots: { customRender: "ptCreateTime" },
  //   dataIndex: "ptCreateTime",
  //   align: "center",
  // },
  {
    title: "操作",
    width: "160px",
    scopedSlots: { customRender: "action" },
    // fixed: "right",
    dataIndex: "action",
    align: "center",
  },
];
// const orderParam = ["ptBcIds"];

export default {
  name: "PackageTemplate",
  components: {
    STable,
    SSearch,
    ColumnsSet,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      SSearchShow: true,
      // 查询参数
      queryParam: {
        ptBcIds: "24",
        pageNo: 1,
        pageSize: 10,
      },
      isShowBtns: [],
      record: {
        ptBcIds: 0,
      },
      CompanyList: {},
      type: "",
      filters: {},
      CompanyListid: [],
    };
  },
  created() {
    this.GetCompaniesId();
    this.tableColumns = columns;
    this.changeColumns();
    this.filters = SelectOptions;
    // console.log(CompaniesResult(24));
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtnsJF").split(",");
  },
  methods: {
    // 获取分公司id
    GetCompaniesId() {
      GetCompanies()
        .then((res) => {
          // console.log(res);
          // this.filters.CompanyList.length = 0;

          res.data.forEach((el) => {
            this.CompanyListid.push(el.bc_id);
            this.CompanyList[el.bc_id] = el.bc_name;
          });
        })
        .then(() => {
          this.queryParam.ptBcIds = this.CompanyListid.toString();
          this.record.ptBcIds = this.CompanyListid.toString();
          this.$refs.table.refresh(true);

          //当包含的分公司小于1个的时候，查询条件不显示分公司查询 表格不显示分公司名称
          if (this.CompanyListid.length < 1) {
            let data = [...this.queryItems];
            this.queryItems = data.filter((item) => item.label != "公司名称");
            if (this.queryItems.length == 0) {
              this.SSearchShow = false;
            }
            let data1 = [...this.columns];
            this.tableColumns = data1.filter(
              (item) => item.title != "公司名称"
            );
            this.columns = data1.filter((item) => item.title != "公司名称");
          }
        });
    },
    CompaniesResult(a) {
      return this.CompanyList[a];
    },
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("PackageTemplateColumnsSet")
      );
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      if (parameter) {
        this.queryParam.pageNo = parameter.pageNo;
        this.queryParam.pageSize = parameter.pageSize;
      } else {
      }
      let newObject = {};
      for (let el in this.queryParam) {
        if (this.queryParam[el] != "") {
          newObject[el] = this.queryParam[el];
        }
      }
      let orderParam = Object.keys(newObject);
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);

      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };

      return GetPackageTemplateList(params).then((res) => {
        // res.data.pageNo = res.data.page;
        // res.data.totalCount = res.data.sum;

        return res.data;
      });
    },
    fetchUsers(value) {
      this.queryParam = value;
      if (this.queryParam.ptBcIds == "") {
        this.queryParam.ptBcIds = this.CompanyListid.toString();
      }
      this.$refs.table.refresh(true);
    },
    // 删除类别信息
    onDelete(record) {
      // console.log("record", record);
      const param = {
        ptId: record.ptId,
      };
      const orderParam = ["ptId"];
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return DeletePackageTemplate(params).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success("删除成功");
          this.$refs.table.refresh(true);
        } else {
          this.$message.error(res.msg);
        }
      });
    },

    // 跳往新建模版页面
    GoToBuildPackage(record, type) {
      // let params = JSON.stringify(record);
      this.$router.push({
        // path: "/BuildPackage",
        name: "BuildPackage",
        params: { record, type },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.delName {
  color: red;
}
.editBtn {
  margin-right: 20px;
}
.table-button-flex {
  display: flex;
  justify-content: space-between;
}
</style>