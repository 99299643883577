var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.SSearchShow)?_c('s-search',{ref:"SSearch",attrs:{"queryItems":_vm.queryItems},on:{"fetchData":_vm.fetchUsers}}):_vm._e(),_c('div',{staticClass:"table-button table-button-flex"},[_c('div',[(
          _vm.isShowBtns.indexOf('PackageTemplate-/api/packageTemplate/add') > -1
        )?_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.GoToBuildPackage(_vm.record, 'add')}}},[_vm._v("新增")]):_vm._e()],1),_c('div',[_c('a-button',{attrs:{"type":"plain","icon":"redo"},on:{"click":function($event){return _vm.$refs.table.refresh(true)}}},[_vm._v("刷新")]),_c('columns-set',{attrs:{"tableName":"PackageTemplate","columns":_vm.columns},on:{"changeColumns":_vm.changeColumns}})],1)]),_c('s-table',{ref:"table",attrs:{"size":"small","rowKey":"ptId","columns":_vm.tableColumns,"data":_vm.loadData,"showPagination":true,"scroll":{ x: '800px', y: 'calc(82vh - 150px )' },"tableLayout":"fixed"},scopedSlots:_vm._u([{key:"ptBcId",fn:function(text){return _c('span',{},[_c('div',[_vm._v(_vm._s(_vm.CompaniesResult(text)))])])}},{key:"tbranchcompany",fn:function(text){return _c('span',{},[_c('div',[_vm._v(_vm._s(text.bcName))])])}},{key:"tpackageType",fn:function(text){return _c('span',{},[_c('div',[_vm._v(_vm._s(text ? text.ptName : ""))])])}},{key:"ptCreateTime",fn:function(text, record){return _c('span',{},[(record.ptCreateTime == '')?_c('div',[_vm._v(_vm._s("-"))]):_vm._e(),_c('div',[_vm._v(_vm._s(_vm.$Format(record.ptCreateTime).slice(0, 10)))])])}},{key:"action",fn:function(text, record){return _c('span',{staticClass:"action"},[(
          _vm.isShowBtns.indexOf('PackageTemplate-/api/packageTemplate/update') >
          -1
        )?_c('a',{staticClass:"editBtn",on:{"click":function($event){return _vm.GoToBuildPackage(record, 'edit')}}},[_vm._v("详情")]):_vm._e(),(
          _vm.isShowBtns.indexOf('PackageTemplate-/api/packageTemplate/delete') >
          -1
        )?_c('a-popconfirm',{attrs:{"title":"确定删除此条套餐信息吗?"},on:{"confirm":() => _vm.onDelete(record)}},[_c('a',{staticClass:"delName"},[_vm._v("删除")])]):_vm._e()],1)}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }